import type { HeadlineResponsePagedData } from '@on3/api';

import { ArticleCover } from './ArticleCover';
import styles from './CoverList.module.scss';

interface IArticleModeListProps extends HeadlineResponsePagedData {
  mode?: 'light' | 'dark';
  page?: 'network' | 'channel' | 'team';
}

export const CoverList = ({
  list = [],
  mode = 'light',
  page = 'network',
}: IArticleModeListProps) => {
  return (
    <section className={styles.block}>
      <div className={styles.container}>
        {list?.map((item, index) => {
          return (
            <div className={styles.box} key={index}>
              <ArticleCover {...item} index={index} mode={mode} page={page} />
            </div>
          );
        })}
      </div>
    </section>
  );
};
