import type { HeadlineResponse } from '@on3/api';
import { On3IconPremiumPlus } from '@on3/icons';
import { toFriendlyDate } from '@on3/ui-lib/src/utils/date';
import clsx from 'clsx';
import { ResizedImage } from 'components/Image/ResizedImage';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';
import { useRouter } from 'next/router';

import styles from './ArticleFeed.module.scss';

interface IArticleFeedProps extends HeadlineResponse {
  primaryColor?: string;
}
export const ArticleFeed = ({
  featuredImage,
  title,
  fullUrl,
  author,
  postDate,
  isPremium,
  primaryCategory,
  primaryColor,
}: IArticleFeedProps) => {
  const router = useRouter();
  const { asPath } = router;
  const isOsHome = asPath === '/os/';
  let baseUrl = primaryCategory?.fullUrl;

  if (baseUrl?.endsWith('/')) {
    baseUrl = baseUrl.slice(0, -1);
  }

  return (
    <article className={clsx(styles.container, { [styles.os]: isOsHome })}>
      {featuredImage && (
        <TextLink href={fullUrl}>
          <ResizedImage
            {...featuredImage}
            className={clsx(styles.image, { [styles.os]: isOsHome })}
            fit="cover"
            gravity="0.5x0.5"
            height={100}
            quality={90}
            width={isOsHome ? 100 : 160}
          />
        </TextLink>
      )}

      <div className={styles.text}>
        {primaryCategory?.name && (
          <TextLink
            className={styles.category}
            href={`${baseUrl}/news/`}
            style={{ color: primaryColor }}
          >
            {primaryCategory?.name}
          </TextLink>
        )}
        <TextLink href={fullUrl}>
          <Text
            className={clsx(styles.title, { [styles.os]: isOsHome })}
            component="h3"
            variant="h6"
          >
            {title}
          </Text>
        </TextLink>

        <div
          className={clsx(styles.authorblock, {
            [styles.isPremiumMobile]: isPremium,
          })}
        >
          <TextLink
            className={styles.name}
            href={`/user/${author?.niceName}/`}
            underline="none"
            variant="caption"
          >
            {author?.name}
          </TextLink>
          <Text className={styles.date} variant="caption">
            <span
              className={clsx(styles.bullet, {
                [styles.isPremiumBullet]: isPremium,
              })}
            >
              &#8226;
            </span>
            {toFriendlyDate(postDate)}
          </Text>
          {isPremium && (
            <On3IconPremiumPlus
              className={clsx(styles.on3Icon, {
                [styles.isPremiumIcon]: isPremium,
              })}
            />
          )}
        </div>
      </div>
    </article>
  );
};
